const educations = [
    {
        title: 'Yönetim Bilişim Sistemleri',
        subtitle: 'Eskişehir Anadolu Üniversitesi',
        date: '2019 - Continue ( 1 Lesson )',
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt commodi, sequi earum aut voluptates dolor! Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt commodi, sequi earum aut voluptates dolor!'
    },
    {
        title: 'Bilgisayar Programcılığı',
        subtitle: 'Niğde Ömer Halisdemir Üniversitesi',
        date: '2017 - 2019',
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt commodi, sequi earum aut voluptates dolor! Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt commodi, sequi earum aut voluptates dolor!'
    }];

export default educations;